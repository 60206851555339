// READ ONLY REDUCER

const petfoodEN = {
  meatyFlakes: 'petfood1meatyflakesenglisch1920x1080',
  catsDeluxe: 'petfood2catsdeluxeenglisch1920x1080',
  catsBar: 'petfood3catsbarenglisch1920x1080',
  catsMousse: 'petfood4catsmouseenglisch1920x1080',
  dogSticks: 'petfood5dogsticksenglisch1920x1080',
  catDogSticks: 'petfood6catdogsticksenglisch1920x1080',
  trockenfutter: 'petfood7trockenfutterenglisch1920x1080',
  xxl: 'petfood8xxlenglisch1920x1080',
};

const foodEN = {
  bioFresh: 'foodinternational1biofreshenglisch1920x1080',
  bamiGoreng: 'foodinternational2ospeltenglisch1920x1080',
  nonnaBlanca: 'foodinternational3nonnablancaenglisch1920x1080',
  bacon: 'foodinternational4ospeltbaconenglisch1920x1080',
  curryBalls: 'foodinternational5ospeltcurryballsenglisch1920x1080',
  streichwurst: 'foodinternational6ospeltstreichwurstenglisch1920x1080',
};

const snacksEN = {
  jerky: 'snacksinternational1jerkyenglisch1920x1080',
  partySticks: 'snacksinternational2partystickenglisch1920x1080',
  pocketSandwich: 'snacksinternational3pocketsandwichenglisch1920x1080',
  jerkyMix: 'snacksinternational4jerkytrailmixenglisch1920x1080',
};

const petfoodDE = {
  meatyFlakes: 'petfood1meatyflakesdeutsch1920x1080',
  catsDeluxe: 'petfood2catsdeluxedeutsch1920x1080',
  catsBar: 'petfood3catsbardeutsch1920x1080',
  catsMousse: 'petfood4catsmousedeutsch1920x1080',
  dogSticks: 'petfood5dogsticksdeutsch1920x1080',
  catDogSticks: 'petfood6catdogsticksdeutsch1920x1080',
  trockenfutter: 'petfood7trockenfutterdeutsch1920x1080',
  xxl: 'petfood8xxldeutsch1920x1080',
};

const foodDE = {
  bioFresh: 'foodinternational1biofreshdeutsch1920x1080',
  bamiGoreng: 'foodinternational2ospeltdeutsch1920x1080',
  nonnaBlanca: 'foodinternational3nonnablancadeutsch1920x1080',
  bacon: 'foodinternational4ospeltbacondeutsch1920x1080',
  curryBalls: 'foodinternational5ospeltcurryballsdeutsch1920x1080',
  streichwurst: 'foodinternational6ospeltstreichwurstdeutsch1920x1080',
};

const snacksDE = {
  jerky: 'snacksinternational1jerkydeutsch1920x1080',
  partySticks: 'snacksinternational2partysticksdeutsch1920x1080',
  pocketSandwich: 'snacksinternational3pocketsandwichdeutsch1920x1080',
  jerkyMix: 'snacksinternational4jerkytrailmixdeutsch1920x1080',
};

export const mit = (name) => name + '/' + name + '.m3u8';
const F_HD = '1920x1080';
const HD = '1280x720';

const initialState = {
  en: {
    'Ospelt Petfood': [
      {
        src: {
          1920: 'data/videos/petfood/en/' + mit(petfoodEN.meatyFlakes),
          550:
            'data/videos/petfood/en/' +
            mit(petfoodEN.meatyFlakes.replace(F_HD, HD)),
        },
        productName: 'Cat’s Meaty Flakes',
        img: '/assets/product-images/Pet/MeatyFlakes.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/en/' + mit(petfoodEN.catsDeluxe),
          550:
            'data/videos/petfood/en/' + mit(petfoodEN.catsDeluxe.replace(F_HD, HD)),
        },
        productName: 'Cat’s Cats Sticks Deluxe',
        img: '/assets/product-images/Pet/CatsDeluxe.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/en/' + mit(petfoodEN.catsBar),
          550: 'data/videos/petfood/en/' + mit(petfoodEN.catsBar.replace(F_HD, HD)),
        },
        productName: 'Cat’s Bar',
        img: '/assets/product-images/Pet/CatsBar.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/en/' + mit(petfoodEN.catsMousse),
          550:
            'data/videos/petfood/en/' + mit(petfoodEN.catsMousse.replace(F_HD, HD)),
        },
        productName: 'Cat’s Mousse with soft center filled',
        img: '/assets/product-images/Pet/Mousse.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/en/' + mit(petfoodEN.dogSticks),
          550:
            'data/videos/petfood/en/' + mit(petfoodEN.dogSticks.replace(F_HD, HD)),
        },
        productName: 'Siro Dog sticks',
        img: '/assets/product-images/Pet/DogStick.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/en/' + mit(petfoodEN.catDogSticks),
          550:
            'data/videos/petfood/en/' +
            mit(petfoodEN.catDogSticks.replace(F_HD, HD)),
        },
        productName: 'Siro Dog sticks & Cat’s cats sticks',
        img: '/assets/product-images/Pet/CatStick.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/en/' + mit(petfoodEN.trockenfutter),
          550:
            'data/videos/petfood/en/' +
            mit(petfoodEN.trockenfutter.replace(F_HD, HD)),
        },
        productName: 'Dry food Cat & Dog',
        img: '/assets/product-images/Pet/Trockenfutter.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/en/' + mit(petfoodEN.xxl),
          550: 'data/videos/petfood/en/' + mit(petfoodEN.xxl.replace(F_HD, HD)),
        },
        productName: 'Cat’s Online Solution XXL Boxen',
        img: '/assets/product-images/Pet/XXL.jpg',
      },
    ],
    'Ospelt Food International': [
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.bioFresh),
          550:
            'data/videos/international/en/' +
            mit(foodEN.bioFresh.replace(F_HD, HD)),
        },
        productName: 'Nonna Blanca',
        img: '/assets/product-images/Food/NonnaBlanca.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.bamiGoreng),
          550:
            'data/videos/international/en/' +
            mit(foodEN.bamiGoreng.replace(F_HD, HD)),
        },
        productName: 'Ospelt Bami Goreng',
        img: '/assets/product-images/Food/OspeltFood.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.nonnaBlanca),
          550:
            'data/videos/international/en/' +
            mit(foodEN.nonnaBlanca.replace(F_HD, HD)),
        },
        productName: 'Bio Fresh',
        img: '/assets/product-images/Food/Kids_BioFresh.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.bacon),
          550:
            'data/videos/international/en/' + mit(foodEN.bacon.replace(F_HD, HD)),
        },
        productName: 'Ospelt Morning Bacon',
        img: '/assets/product-images/Food/Bacon.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.curryBalls),
          550:
            'data/videos/international/en/' +
            mit(foodEN.curryBalls.replace(F_HD, HD)),
        },
        productName: 'Ospelt Liver Meat Spread',
        img: '/assets/product-images/Food/Streichwurst.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.streichwurst),
          550:
            'data/videos/international/en/' +
            mit(foodEN.streichwurst.replace(F_HD, HD)),
        },
        productName: 'Ospelt Chicken Curry Balls',
        img: '/assets/product-images/Food/CurryBalls.jpg',
      },
    ],
    'Ospelt Snacks International': [
      {
        src: {
          1920: 'data/videos/snacks/en/' + mit(snacksEN.jerky),
          550: 'data/videos/snacks/en/' + mit(snacksEN.jerky.replace(F_HD, HD)),
        },
        productName: 'Conower Beef Jerky',
        img: '/assets/product-images/Snack/Jerky.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/en/' + mit(snacksEN.partySticks),
          550:
            'data/videos/snacks/en/' + mit(snacksEN.partySticks.replace(F_HD, HD)),
        },
        productName: 'Conower Party Sticks',
        img: '/assets/product-images/Snack/PartyStick.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/en/' + mit(snacksEN.pocketSandwich),
          550:
            'data/videos/snacks/en/' +
            mit(snacksEN.pocketSandwich.replace(F_HD, HD)),
        },
        productName: 'Conower Pocket Sandwich',
        img: '/assets/product-images/Snack/PocketSandwish.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/en/' + mit(snacksEN.jerkyMix),
          550: 'data/videos/snacks/en/' + mit(snacksEN.jerkyMix.replace(F_HD, HD)),
        },
        productName: 'Conower Jerky Trail Mix',
        img: '/assets/product-images/Snack/NussMix.jpg',
      },
    ],
    'Ospelt Food & Snacks International': [
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.bioFresh),
          550:
            'data/videos/international/en/' +
            mit(foodEN.bioFresh.replace(F_HD, HD)),
        },
        productName: 'Nonna Blanca',
        img: '/assets/product-images/Food/NonnaBlanca.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.bamiGoreng),
          550:
            'data/videos/international/en/' +
            mit(foodEN.bamiGoreng.replace(F_HD, HD)),
        },
        productName: 'Ospelt Bami Goreng',
        img: '/assets/product-images/Food/OspeltFood.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.nonnaBlanca),
          550:
            'data/videos/international/en/' +
            mit(foodEN.nonnaBlanca.replace(F_HD, HD)),
        },
        productName: 'Bio Fresh',
        img: '/assets/product-images/Food/Kids_BioFresh.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.bacon),
          550:
            'data/videos/international/en/' + mit(foodEN.bacon.replace(F_HD, HD)),
        },
        productName: 'Ospelt Morning Bacon',
        img: '/assets/product-images/Food/Bacon.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.curryBalls),
          550:
            'data/videos/international/en/' +
            mit(foodEN.curryBalls.replace(F_HD, HD)),
        },
        productName: 'Ospelt Liver Meat Spread',
        img: '/assets/product-images/Food/Streichwurst.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/en/' + mit(foodEN.streichwurst),
          550:
            'data/videos/international/en/' +
            mit(foodEN.streichwurst.replace(F_HD, HD)),
        },
        productName: 'Ospelt Chicken Curry Balls',
        img: '/assets/product-images/Food/CurryBalls.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/en/' + mit(snacksEN.jerky),
          550: 'data/videos/snacks/en/' + mit(snacksEN.jerky.replace(F_HD, HD)),
        },
        productName: 'Conower Beef Jerky',
        img: '/assets/product-images/Snack/Jerky.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/en/' + mit(snacksEN.partySticks),
          550:
            'data/videos/snacks/en/' + mit(snacksEN.partySticks.replace(F_HD, HD)),
        },
        productName: 'Conower Party Sticks',
        img: '/assets/product-images/Snack/PartyStick.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/en/' + mit(snacksEN.pocketSandwich),
          550:
            'data/videos/snacks/en/' +
            mit(snacksEN.pocketSandwich.replace(F_HD, HD)),
        },
        productName: 'Conower Pocket Sandwich',
        img: '/assets/product-images/Snack/PocketSandwish.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/en/' + mit(snacksEN.jerkyMix),
          550: 'data/videos/snacks/en/' + mit(snacksEN.jerkyMix.replace(F_HD, HD)),
        },
        productName: 'Conower Jerky Trail Mix',
        img: '/assets/product-images/Snack/NussMix.jpg',
      },
    ],
  },
  de: {
    'Ospelt Petfood': [
      {
        src: {
          1920: 'data/videos/petfood/de/' + mit(petfoodDE.meatyFlakes),
          550:
            'data/videos/petfood/de/' +
            mit(petfoodDE.meatyFlakes.replace(F_HD, HD)),
        },
        productName: 'Cat’s Meaty Flakes',
        img: '/assets/product-images/Pet/MeatyFlakes.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/de/' + mit(petfoodDE.catsDeluxe),
          550:
            'data/videos/petfood/de/' + mit(petfoodDE.catsDeluxe.replace(F_HD, HD)),
        },
        productName: 'Cat’s Katzensticks Deluxe',
        img: '/assets/product-images/Pet/CatsDeluxe.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/de/' + mit(petfoodDE.catsBar),
          550: 'data/videos/petfood/de/' + mit(petfoodDE.catsBar.replace(F_HD, HD)),
        },
        productName: 'Cat’s Bar',
        img: '/assets/product-images/Pet/CatsBar.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/de/' + mit(petfoodDE.catsMousse),
          550:
            'data/videos/petfood/de/' + mit(petfoodDE.catsMousse.replace(F_HD, HD)),
        },
        productName: 'Cat’s Mousse mit Softkern',
        img: '/assets/product-images/Pet/Mousse.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/de/' + mit(petfoodDE.dogSticks),
          550:
            'data/videos/petfood/de/' + mit(petfoodDE.dogSticks.replace(F_HD, HD)),
        },
        productName: 'Siro Hundesticks',
        img: '/assets/product-images/Pet/DogStick.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/de/' + mit(petfoodDE.catDogSticks),
          550:
            'data/videos/petfood/de/' +
            mit(petfoodDE.catDogSticks.replace(F_HD, HD)),
        },
        productName: 'Siro Hundesticks & Cat’s Katzensticks',
        img: '/assets/product-images/Pet/CatStick.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/de/' + mit(petfoodDE.trockenfutter),
          550:
            'data/videos/petfood/de/' +
            mit(petfoodDE.trockenfutter.replace(F_HD, HD)),
        },
        productName: 'Trockennahrung Katze & Hund',
        img: '/assets/product-images/Pet/Trockenfutter.jpg',
      },
      {
        src: {
          1920: 'data/videos/petfood/de/' + mit(petfoodDE.xxl),
          550: 'data/videos/petfood/de/' + mit(petfoodDE.xxl.replace(F_HD, HD)),
        },
        productName: "Cat's Online Solution XXL Boxen",
        img: '/assets/product-images/Pet/XXL.jpg',
      },
    ],
    'Ospelt Food International': [
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.bioFresh),
          550:
            'data/videos/international/de/' +
            mit(foodDE.bioFresh.replace(F_HD, HD)),
        },
        productName: 'Nonna Blanca',
        img: '/assets/product-images/Food/NonnaBlanca.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.bamiGoreng),
          550:
            'data/videos/international/de/' +
            mit(foodDE.bamiGoreng.replace(F_HD, HD)),
        },
        productName: 'Ospelt Bami Goreng',
        img: '/assets/product-images/Food/OspeltFood.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.nonnaBlanca),
          550:
            'data/videos/international/de/' +
            mit(foodDE.nonnaBlanca.replace(F_HD, HD)),
        },
        productName: 'Bio Fresh',
        img: '/assets/product-images/Food/Kids_BioFresh.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.bacon),
          550:
            'data/videos/international/de/' + mit(foodDE.bacon.replace(F_HD, HD)),
        },
        productName: 'Ospelt Frühstücks Bacon',
        img: '/assets/product-images/Food/Bacon.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.curryBalls),
          550:
            'data/videos/international/de/' +
            mit(foodDE.curryBalls.replace(F_HD, HD)),
        },
        productName: 'Ospelt Streichwurst',
        img: '/assets/product-images/Food/Streichwurst.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.streichwurst),
          550:
            'data/videos/international/de/' +
            mit(foodDE.streichwurst.replace(F_HD, HD)),
        },
        productName: 'Ospelt Chicken Curry Balls',
        img: '/assets/product-images/Food/CurryBalls.jpg',
      },
    ],
    'Ospelt Snacks International': [
      {
        src: {
          1920: 'data/videos/snacks/de/' + mit(snacksDE.jerky),
          550: 'data/videos/snacks/de/' + mit(snacksDE.jerky.replace(F_HD, HD)),
        },
        productName: 'Conower Beef Jerky',
        img: '/assets/product-images/Snack/Jerky.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/de/' + mit(snacksDE.partySticks),
          550:
            'data/videos/snacks/de/' + mit(snacksDE.partySticks.replace(F_HD, HD)),
        },
        productName: 'Conower Partysticks',
        img: '/assets/product-images/Snack/PartyStick.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/de/' + mit(snacksDE.pocketSandwich),
          550:
            'data/videos/snacks/de/' +
            mit(snacksDE.pocketSandwich.replace(F_HD, HD)),
        },
        productName: 'Conower Pocket Sandwich',
        img: '/assets/product-images/Snack/PocketSandwish.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/de/' + mit(snacksDE.jerkyMix),
          550: 'data/videos/snacks/de/' + mit(snacksDE.jerkyMix.replace(F_HD, HD)),
        },
        productName: 'Conower Jerky Trail Mix',
        img: '/assets/product-images/Snack/NussMix.jpg',
      },
    ],
    'Ospelt Food & Snacks International': [
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.bioFresh),
          550:
            'data/videos/international/de/' +
            mit(foodDE.bioFresh.replace(F_HD, HD)),
        },
        productName: 'Nonna Blanca',
        img: '/assets/product-images/Food/NonnaBlanca.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.bamiGoreng),
          550:
            'data/videos/international/de/' +
            mit(foodDE.bamiGoreng.replace(F_HD, HD)),
        },
        productName: 'Ospelt Bami Goreng',
        img: '/assets/product-images/Food/OspeltFood.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.nonnaBlanca),
          550:
            'data/videos/international/de/' +
            mit(foodDE.nonnaBlanca.replace(F_HD, HD)),
        },
        productName: 'Bio Fresh',
        img: '/assets/product-images/Food/Kids_BioFresh.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.bacon),
          550:
            'data/videos/international/de/' + mit(foodDE.bacon.replace(F_HD, HD)),
        },
        productName: 'Ospelt Frühstücks Bacon',
        img: '/assets/product-images/Food/Bacon.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.curryBalls),
          550:
            'data/videos/international/de/' +
            mit(foodDE.curryBalls.replace(F_HD, HD)),
        },
        productName: 'Ospelt Streichwurst',
        img: '/assets/product-images/Food/Streichwurst.jpg',
      },
      {
        src: {
          1920: 'data/videos/international/de/' + mit(foodDE.streichwurst),
          550:
            'data/videos/international/de/' +
            mit(foodDE.streichwurst.replace(F_HD, HD)),
        },
        productName: 'Ospelt Chicken Curry Balls',
        img: '/assets/product-images/Food/CurryBalls.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/de/' + mit(snacksDE.jerky),
          550: 'data/videos/snacks/de/' + mit(snacksDE.jerky.replace(F_HD, HD)),
        },
        productName: 'Conower Beef Jerky',
        img: '/assets/product-images/Snack/Jerky.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/de/' + mit(snacksDE.partySticks),
          550:
            'data/videos/snacks/de/' + mit(snacksDE.partySticks.replace(F_HD, HD)),
        },
        productName: 'Conower Partysticks',
        img: '/assets/product-images/Snack/PartyStick.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/de/' + mit(snacksDE.pocketSandwich),
          550:
            'data/videos/snacks/de/' +
            mit(snacksDE.pocketSandwich.replace(F_HD, HD)),
        },
        productName: 'Conower Pocket Sandwich',
        img: '/assets/product-images/Snack/PocketSandwish.jpg',
      },
      {
        src: {
          1920: 'data/videos/snacks/de/' + mit(snacksDE.jerkyMix),
          550: 'data/videos/snacks/de/' + mit(snacksDE.jerkyMix.replace(F_HD, HD)),
        },
        productName: 'Conower Jerky Trail Mix',
        img: '/assets/product-images/Snack/NussMix.jpg',
      },
    ],
  },
};

export default function (state = initialState) {
  return { ...state };
}
