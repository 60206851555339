import {
  SET_PERSONAL_DATA,
  SET_LAST_WATCHED_VIDEO,
  ADD_PRODUCT_TO_CART,
  SET_PRODUCT_REQUESTED,
  UPDATE_PRODUCT_VIDEOS_IN_CART,
  UPDATE_PRODUCT_VIDEOS_IN_CART_REQUEST,
} from "../types";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import logService from "services/logService";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const initialState = {
  _id: "",
  code: "",
  name: "",
  company: "",
  email: "",
  category: "",
  productsInCart: [],
  products: [],
  productVideos: {
    lastWatched: 0,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PERSONAL_DATA: {
      const {
        _id,
        code,
        name,
        company,
        email,
        category,
        manager,
        managerJob,
        managerMail,
        managerTel,
        managerMobile,
        products,
      } = action.data;

      const productArray = [];

      for (let i = 0; i < products.length; i++) {
        productArray.push({
          name: products[i].productName,
          img: products[i].img,
          requested: false,
          qty: 0,
        });
      }

      // add Stories
      if (category === "Ospelt Food & Snacks International") {
        productArray.push({
          name: "Story Nonna Blanca",
          requested: true,
          qty: 0,
          img: "/assets/logos/nonna-blanca.png",
        });
        productArray.push({
          name: "Story Bio Fresh",
          requested: true,
          qty: 0,
          img: "/assets/logos/bio-fresh.png",
        });
        productArray.push({
          name: "Story Conower",
          requested: true,
          qty: 0,
          img: "/assets/logos/conower.png",
        });
      }
      if (category === "Ospelt Food International") {
        productArray.push({
          name: "Story Nonna Blanca",
          requested: true,
          qty: 0,
          img: "/assets/logos/nonna-blanca.png",
        });
        productArray.push({
          name: "Story Bio Fresh",
          requested: true,
          qty: 0,
          img: "/assets/logos/bio-fresh.png",
        });
      }
      if (category === "Ospelt Snacks International") {
        productArray.push({
          name: "Story Conower",
          requested: true,
          qty: 0,
          img: "/assets/logos/conower.png",
        });
      }

      return {
        ...state,
        _id,
        code,
        name,
        email,
        company,
        category,
        manager,
        managerJob,
        managerMail,
        managerTel,
        managerMobile,
        products: productArray,
      };
    }

    case SET_LAST_WATCHED_VIDEO: {
      return {
        ...state,
        productVideos: {
          lastWatched: action.data,
        },
      };
    }
    case SET_PRODUCT_REQUESTED: {
      const { name, requested, qty } = action.data;
      const productsArray = [];
      const logString = [];

      const updateLog = async (id, data) => {
        try {
          let res = await logService.save(id, data);
        } catch (e) {}
      };

      (function () {
        for (let i = 0; i < state.products.length; i++) {
          if (state.products[i].name === name) {
            productsArray.push({ ...state.products[i], name, requested, qty });
            logString.push(name + ` Requested: Yes `);
          } else {
            productsArray.push(state.products[i]);
            logString.push(
              state.products[i].name +
                ` Requested: ${state.products[i].requested ? "Yes " : "No "}`
            );
          }
        }
      })();

      updateLog(state._id, {
        date: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
        description: `${name} wurde bestellt`,
        data: logString.toString(),
      });

      return {
        ...state,
        /* products: state.products.concat({ name, requested }), */
        products: productsArray,
      };
    }

    case ADD_PRODUCT_TO_CART: {
      const { name, requested } = action.data;
      return {
        ...state,
        productsInCart: state.productsInCart.concat({ name, requested }),
      };
    }

    case UPDATE_PRODUCT_VIDEOS_IN_CART: {
      const products = [];
      for (let i = 0; i < action.data.length; i++) {
        products.push({
          name: action.data[i].productName,
          requested: state.products[i].requested,
          qty: state.products[i].qty,
        });
      }
      return { ...state, products: products };
    }

    case UPDATE_PRODUCT_VIDEOS_IN_CART_REQUEST: {
      const updateLog = async (id, data) => {
        try {
          let res = await logService.save(id, data);
        } catch (e) {}
      };

      const products = state.products;
      products[action.data.position].requested = action.data.requested;
      products[action.data.position].qty = action.data.qty;

      updateLog(state._id, {
        date: dayjs(new Date()).format("YYYY-MM-DDTHH:mm"),
        description: `${state.products[action.data.position].name} ${
          action.data.requested ? " Wurde bestellt" : " Wurde entfernt"
        }`,
      });

      return { ...state, products: products };
    }
    default:
      return { ...state };
  }
}
